
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { Survey, SurveyResponses, SurveyTheme, SurveyType } from '../../models';
import { defaultSurveyThemes, defaultSurveyTypes } from '../../defaults';
import { surveyService } from '../../services';
import SurveyActions from './SurveyActions.vue';

@Component({ components: { SurveyActions } })
export default class SurveyTile extends Vue {
  @Prop({ type: Object as () => Survey, required: true })
  survey!: Survey;

  responses: SurveyResponses = [];
  formatDate = formatDate;

  get theme(): SurveyTheme {
    return defaultSurveyThemes[this.survey.theme];
  }
  get surveyType(): SurveyType {
    return defaultSurveyTypes[this.survey.type];
  }
  get surveyDuration(): number {
    return this.survey.questions ? Math.round(this.survey.questions.length / 3) : 0;
  }
  get surveyParticipants(): number {
    return this.responses ? this.responses.length : 0;
  }

  created() {
    this.$bind('responses', surveyService.getResponsesRef(this.survey.id));
  }
}
