
import { Vue, Component, ModelSync } from 'vue-property-decorator';
import { Survey } from '../../models';
import { surveyService } from '../../services';

@Component({ components: {} })
export default class SurveyImportDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  importing = false;
  importFiles: File[] = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  async onSurveyImport() {
    try {
      for (let file of this.importFiles) {
        this.importing = true;
        const survey: Survey = await surveyService.importSurvey(file);
        const responses = Object.values(survey.responses || {});
        delete survey.responses;
        await surveyService.createSurvey(survey);
        for (let response of responses) {
          await surveyService.createResponse(survey.id, response);
        }
        this.$bus.$emit('snackbar-notify', 'Survey successfully imported', 'success');
        this.show = false;
        this.importFiles = [];
        this.$analytics.logEvent('survey-import', { survey_id: survey.id, user_id: this.userId });
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
    this.importing = false;
  }
}
