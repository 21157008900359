
import { Vue, Component } from 'vue-property-decorator';
import { Surveys } from '../../models';
import { surveyService } from '../../services';
import SurveysList from './SurveysList.vue';

@Component({ components: { SurveysList } })
export default class SurveysPanel extends Vue {
  draftSurveys: Surveys = [];
  activeSurveys: Surveys = [];
  closedSurveys: Surveys = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  created() {
    this.$bind('draftSurveys', surveyService.getDraftSurveys(this.userId));
    this.$bind('activeSurveys', surveyService.getActiveSurveys(this.userId));
    this.$bind('closedSurveys', surveyService.getClosedSurveys(this.userId));
  }
}
