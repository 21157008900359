
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Surveys } from '../../models';
import SurveyTile from './SurveyTile.vue';
import SurveyRow from './SurveyRow.vue';

@Component({ components: { SurveyTile, SurveyRow } })
export default class SurveysList extends Vue {
  @Prop({ type: Array as () => Surveys, required: true }) readonly surveys!: Surveys;

  get viewModeGrid(): boolean {
    return this.$store.getters['user/viewModeGrid'];
  }
}
