
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Survey, SurveyTheme } from '../../models';
import { defaultSurveyTheme } from '../../defaults';

@Component
export default class SurveyActions extends Vue {
  @Prop({ type: Object as () => Survey, required: true }) readonly survey!: Survey;

  @Prop({ type: Object as () => SurveyTheme, default: () => defaultSurveyTheme }) readonly theme!: SurveyTheme;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  get isOwner(): boolean {
    return this.survey.owner === this.userId;
  }
}
