
import { Vue, Component } from 'vue-property-decorator';
import { Survey, User } from '../models';
import { surveyService } from '../services';
import SurveysPanel from '../components/survey/SurveysPanel.vue';
import SurveyImportDialog from '../components/survey/SurveyImportDialog.vue';

@Component({ components: { SurveysPanel, SurveyImportDialog } })
export default class SurveyDashboardView extends Vue {
  showImportDialog = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['user/viewModeIcon'];
  }

  created() {
    // Event listeners
    this.$bus.$off('survey-clone').$on('survey-clone', this.onSurveyClone);
    this.$bus.$off('survey-export').$on('survey-export', this.onSurveyExport);
    this.$bus.$off('survey-delete').$on('survey-delete', this.onSurveyDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
    document.title = 'AgileMate Surveys';
    this.$analytics.logEvent('survey-dashboard');
  }

  // Event handlers
  async onSurveyClone(survey: Survey) {
    try {
      const newSurvey = await surveyService.cloneSurvey(survey, this.user);
      this.$bus.$emit('snackbar-notify', 'Survey successfully cloned', 'success');
      this.$analytics.logEvent('survey-clone', {
        survey_id: survey.id,
        user_id: this.userId,
        new_survey_id: newSurvey.id,
      });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSurveyExport(survey: Survey) {
    try {
      await surveyService.exportSurvey(survey);
      this.$bus.$emit('snackbar-notify', 'Survey successfully exported', 'success');
      this.$analytics.logEvent('survey-export', { survey_id: survey.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSurveyDelete(survey: Survey) {
    try {
      await surveyService.deleteSurvey(survey.id);
      this.$bus.$emit('snackbar-notify', 'Survey successfully deleted', 'success');
      this.$analytics.logEvent('survey-delete', { survey_id: survey.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
